<template>
  <div class="consultingBooking">
    <div class="title">个人预约记录</div>
    <div class="contents" v-for="(item, idx) in datas" v-if="item.oppointmentState != 'done'" :key="idx">
      <div class="contents-a">
        <div class="time-box">
          <span>预约时间：{{item.OppointmentTime}}</span>
          <span>预约类型：{{item.OppointmentType}}</span>
        </div>
        <div style="text-align: left; padding-top: 15px">
          <span>预约状态：{{item.jz}}</span>
          <span>医生：{{item.Expert.ExpertName}}</span>
        </div>
      </div>
      <el-button @click="gojc(item.OppointmentTargetChooseId, item.OppointmentId)" type="primary">进入检查</el-button>
    </div>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import pf from '@/publicFn/baseFn'
export default {
  name: "consultingBooking",
  data() {
     return {
       arr: [1,2,3,4,5,6,7],
       datas: []
     }
  },
  props: {
    idxs: {
      default() {
        return 0
      }
    }
  },
  watch: {
    idxs: function (newData, old) {
      if (this.datas.length < 2 && newData==3) {

        this.getData()
      }
    },
    '$store.state.physicianVisits.cancellation': function (newData) {
      if (newData) {
        this.datas = []
      }
    }
  },
  methods: {
    getData() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.getReservationList()
          .then(res  => {
            loadingInstance.close();
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length > 0) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                if (res.GetListResult[i].OppointmentTargetChooseId !== '' && res.GetListResult[i].OppointmentTargetChooseId!='00000000-0000-0000-0000-000000000000') {
                  res.GetListResult[i].zt = '已就诊'
                }else {
                  res.GetListResult[i].zt = '未就诊'
                }
                if (!res.GetListResult[i].Expert) {
                  res.GetListResult[i].Expert = {
                    ExpertName: ""
                  }
                }
              }
              this.datas = [...res.GetListResult]
            } else {
              // this.$message({
              //   showClose: true,
              //   message: '暂无数据',
              //   type: 'error'
              // })
            }

          })
          .catch(err => {
            loadingInstance.close();
          })
    },
    gojc(id, opid) {
      if (id == "00000000-0000-0000-0000-000000000000" || !id) {
        this._api.physicianVisits.addNewChoose(id, opid)
            .then(res => {
              if (res.msg != 'Success') {
                this.$alert(res.msg, '提示')
                return
              }
              if (res.InsertResult) {
                pf.clCsData()
                this.sx = true
                this.$store.commit('physicianVisits/upXzConsulting',  res.InsertResult.Choose.ChooseId)
                this.$store.commit('physicianVisits/upJump', 1)
                this.$router.push({path: '/'})
              }
            })
      }else {
        pf.clCsData()
        this.sx = true
        this.$store.commit('physicianVisits/upXzConsulting',  id)
        this.$store.commit('physicianVisits/upJump', 1)
        this.$router.push({path: '/'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .consultingBooking {
    width: 9rem;
    height: 500px;
    overflow-y: auto;
    padding: 0 5px;
  }
  .title {
    font-size: 16px;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .contents {
    padding: 10px;
    border-bottom: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {background: #f3f2f2
    }
    .contents-a {
      width: 30vw;
      font-size: 14px;
      color: #5a5959;
      .time-box {
        display: flex;
        justify-content: start;
        text-align: left;
        span:nth-child(1) {
          display: block;
          width: 3rem;
          padding-right: 0.7rem;
        }
      }
    }
  }
</style>
